import { handleResponse } from "../../helpers/requestHelpers";
import Config from "../../../Config";
import { fetchWithSessionHandling } from "../../helpers/sessionHandling/fetchWithSessionHandling";

const config = new Config();

export function getWeeklyPlan(date, accessToken) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(
            `${config.apiUrl}/GetCustomerWeeklyPlan/${date.toISOString()}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function getAllCourses() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/GetAllCourses`, requestOptions).then(
            (response) => handleResponse(response),
            (err) => console.log(err)
        );
    };
}

export function deleteCustomerCookingProcess(cookingProcessId, accessToken) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(
            `${config.apiUrl}/deleteCustomerCookingProcess/${cookingProcessId}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}
