import React, { useState, useEffect, useRef } from "react";
import { Dimensions, ScrollView, View, Pressable, StyleSheet, Platform } from "react-native";
import {
    Button,
    Card,
    Checkbox,
    DataTable,
    Dialog,
    IconButton,
    Portal,
    RadioButton,
    Text,
    Title,
    withTheme,
} from "react-native-paper";
import { Link } from "../../../routers/routing";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Picker } from "@react-native-picker/picker";
import { createYupSchema, desktopBreakpoint, getContrastColor, getAccessToken } from "../../../shared/helpers";
import { styles } from "../../../shared/styles";
import CustomerCheckoutAddAddress from "../CustomerCheckoutAddAddress";
import { customerDataStyles } from "../CustomerDataStyles";
import CustomInputField from "../../../helpers/inputField/CustomInputField";
import MissingAddressPopup from "../MissingAddressPopup";
import AddAddressPopup from "../AddAddressPopup";
import { Formik, Form } from "formik";
import { getCustomerAddresses } from "../../../store/actions/accountActions";
import CustomDropdown from "../../../helpers/inputField/CustomDropdown";
import Summary from "./Summary";
import DialogActions from "./DialogActions";
import PaymentForm from "./PaymentForm";
import DialogContent from "./DialogContent";
import FormElements from "./FormElements";
import { setGastFormData } from "../../../store/actions/gastAction";

const CustomerData = (props) => {
    const [useDifferentBillingAddress, setUseDifferentBillingAddress] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(Dimensions.get("window").width);
    const [windowHeight, setWindowHeight] = useState(Dimensions.get("window").height);
    const [addresses, setAddresses] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [step, setStep] = useState(0);
    const [showMissingAddressPopup, setShowMissingAddressPopup] = useState(false);
    const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
    const [additionalAddressToEdit, setAdditionalAddressToEdit] = useState(null);
    const [edit, setEdit] = useState(false);
    const [datenschutzIsChecked, setDatenschutzIsChecked] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const customerDataForm = useRef(null);
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    const settings = useSelector((state) => state.settings);
    const gast = useSelector((state) => state.gast);
    const { theme } = props;

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(Dimensions.get("window").width);
            setWindowHeight(Dimensions.get("window").height);
        };

        Dimensions.addEventListener("change", updateDimensions);

        return () => {
            Dimensions.removeEventListener("change", updateDimensions);
            document.body.style.overflow = "scroll";
        };
    }, [account.customer]);

    useEffect(() => {
        if (props.open && account.customer) {
            fetchCustomerAddresses(getAccessToken());
        }
    }, [props.open, account.customer]);

    useEffect(() => {
        if (!props.open) {
            setDatenschutzIsChecked(false);
            setStep(0);
        }
    }, [props.open]);

    useEffect(() => {
        if (account.customer) {
            setFormIsValid(true);
        }
    }, [account.customer]);

    const saveGastCustomerData = (value) => {
        if (!account.customer) {
            dispatch(setGastFormData(value));
        }
        return;
    };

    const fetchCustomerAddresses = async (token) => {
        try {
            const response = await dispatch(getCustomerAddresses(token));
            if (response.ok) {
                const addresses = await response.json();
                setAddresses(addresses);

                if (
                    addresses &&
                    addresses.find((address) => address.isDefaultBillingAddress)?.addressId !==
                        addresses.find((address) => address.isDefaultDeliveryAddress)?.addressId
                ) {
                    customerDataForm.current.setFieldValue("useDifferentBillingAddress", true);
                } else {
                    customerDataForm.current.setFieldValue("useDifferentBillingAddress", false);
                }
            } else {
                console.error("Failed to fetch customer addresses:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching customer addresses:", error);
        }
    };

    const refreshAddresses = () => {
        fetchCustomerAddresses(getAccessToken());
    };

    const buildInitialValues = async () => {
        const initialValues = {};
        const { customer } = account;

        settings.customerData.fields.forEach((field) => {
            let initialValue = field.value || "";
            if (field.id === "printBill" || field.id === "printRecipe" || field.id === "acceptTerms") {
                initialValue = false;
            } else if (field.id === "billingAddress") {
                if (customer && addresses) {
                    let address = addresses.filter((address) => address.isDefaultBillingAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "deliveryAddress") {
                if (customer && addresses) {
                    let address = addresses.filter((address) => address.isDefaultDeliveryAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "useDifferentBillingAddress") {
                if (
                    customer &&
                    addresses &&
                    addresses.find((address) => address.isDefaultBillingAddress)?.addressId !==
                        addresses.find((address) => address.isDefaultDeliveryAddress)?.addressId
                ) {
                    initialValue = true;
                }
            } else if (field.id === "country") {
                const defaultCountry = settings.store.countries.find((country) => country.isDefault);
                initialValue = defaultCountry ? defaultCountry.countryId : initialValue;
            } else if (field.id === "billingCountry") {
                const defaultCountry = settings.store.countries.find((country) => country.isDefault);
                initialValue = defaultCountry ? defaultCountry.countryId : initialValue;
            } else {
                if (account.customer) {
                    initialValue = account.customer[field.id] ? account.customer[field.id] : initialValue;
                }
            }

            initialValues[field.id] = field.value || initialValue;
        });

        initialValues.paymentMethod = settings.store.availablePaymentTypes[0]?.identifier;

        return initialValues;
    };

    const toggleAddAddressPopup = () => {
        setShowAddAddressPopup(!showAddAddressPopup);
        setAdditionalAddressToEdit(edit ? null : additionalAddressToEdit);
        setEdit(false);
    };

    const openAddAddressPopup = () => {
        setShowAddAddressPopup(true);
    };

    const toggleMissingAddressPopup = () => {
        setShowMissingAddressPopup(!showMissingAddressPopup);
    };

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const initialValues = {
        ...buildInitialValues(),
        paymentMethod: settings.store.availablePaymentTypes[0]?.identifier,
        acceptTerms: false,
        country:
            buildInitialValues().country ||
            settings.store.countries.find((country) => country.isDefault)?.countryId ||
            "",
    };
    const yupSchema = settings.customerData.fields.reduce((schema, fieldConfig) => {
        return createYupSchema(schema, fieldConfig, account.customer);
    }, {});
    const validateSchema = yup.object().shape(yupSchema);

    return (
        <Portal>
            <Dialog
                visible={props.open}
                onDismiss={props.hideCustomerData}
                style={[
                    styles.addressDialogContainer,
                    {
                        backgroundColor: theme.colors.surface,
                        left: windowWidth <= desktopBreakpoint ? "calc(50% - 45vw)" : "calc(50% - 25vw)",
                        width: windowWidth <= desktopBreakpoint ? "90vw" : "50vw",
                    },
                ]}
                dismissable={false}
            >
                <IconButton
                    icon="close"
                    size={24}
                    onPress={props.hideCustomerData}
                    style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                    iconColor={getContrastColor(theme.colors.primary)}
                    rippleColor="transparent"
                />

                {step === 0 && <Dialog.Title style={styles.dialogHeadline}>Schritt 1: Kundendaten</Dialog.Title>}
                {step === 1 && <Dialog.Title style={styles.dialogHeadline}>Schritt 2: Zahlungsinfo</Dialog.Title>}
                {step === 2 && <Dialog.Title style={styles.dialogHeadline}>Schritt 3: Zusammenfassung</Dialog.Title>}

                <Dialog.ScrollArea
                    style={{
                        borderColor: theme.colors.surface,
                    }}
                >
                    <ScrollView style={customerDataStyles.container}>
                        <DialogContent
                            theme={theme}
                            initialValues={initialValues}
                            validateSchema={validateSchema}
                            settings={settings}
                            customerDataForm={customerDataForm}
                            setFormIsValid={setFormIsValid}
                            formElements={(props) => (
                                <FormElements
                                    settings={settings}
                                    props={props}
                                    account={account}
                                    addresses={addresses}
                                    windowWidth={windowWidth}
                                    theme={theme}
                                    refreshAddresses={refreshAddresses}
                                    setDatenschutzIsChecked={setDatenschutzIsChecked}
                                    datenschutzIsChecked={datenschutzIsChecked}
                                    saveGastCustomerData={saveGastCustomerData}
                                    gast={gast}
                                />
                            )}
                            PaymentForm={PaymentForm}
                            windowWidth={windowWidth}
                            windowHeight={windowHeight}
                            step={step}
                            handleSubmit={props.handleSubmit}
                            setPaymentMethod={setPaymentMethod}
                        />
                    </ScrollView>
                    <DialogActions
                        gast={gast}
                        windowWidth={windowWidth}
                        theme={theme}
                        hideCustomerData={props.hideCustomerData}
                        account={account}
                        customerDataForm={customerDataForm}
                        submitButtonName={props.submitButtonName}
                        step={step}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        paymentMethod={paymentMethod}
                        datenschutzIsChecked={datenschutzIsChecked}
                        addresses={addresses}
                        toggleMissingAddressPopup={toggleMissingAddressPopup}
                        desktopBreakpoint={desktopBreakpoint}
                        formIsValid={formIsValid}
                    />
                </Dialog.ScrollArea>
                {showMissingAddressPopup && (
                    <MissingAddressPopup
                        visible={showMissingAddressPopup}
                        toggleOverlay={toggleMissingAddressPopup}
                        onAddAddress={openAddAddressPopup}
                        windowHeight={windowHeight}
                    />
                )}
                {showAddAddressPopup && (
                    <AddAddressPopup
                        visible={showAddAddressPopup}
                        togglePopup={toggleAddAddressPopup}
                        numberOfAddressesSaved={account.customer ? account.customer.addresses.length : 0}
                        addressIndex={0}
                        addressToEdit={additionalAddressToEdit}
                        edit={edit}
                        theme={theme}
                        update={refreshAddresses}
                    />
                )}
            </Dialog>
        </Portal>
    );
};

export default withTheme(CustomerData);
