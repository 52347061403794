import React, { useState, useEffect } from "react";
import { View, ScrollView } from "react-native";
import { ActivityIndicator, DataTable, Text, List, useTheme } from "react-native-paper";
import { getOrderHistory } from "../../store/actions/accountActions";
import { desktopBreakpoint, getContrastColor, tabletBreakpoint, getAccessToken } from "../../shared/helpers";
import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import CustomerNavigation from "./CustomerNavigation";
import { useHistory } from "react-router-dom";
import { styles } from "../../shared/styles";
import { orderHistoryStyles } from "./OrderHistoryStyle";

const OrderHistory = ({ windowWidth }) => {
    const history = useHistory();
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(windowWidth <= tabletBreakpoint);
    const isDesktop = windowWidth >= desktopBreakpoint;
    const theme = useTheme();
    const accordionTheme = {
        ...theme,
        colors: {
            ...theme.colors,
            background: theme.colors.backdrop,
            onBackground: "rgb(225, 225, 225)",
        },
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const accessToken = getAccessToken();
                const data = await getOrderHistory(accessToken);
                setOrders(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    useEffect(() => {
        setIsMobile(windowWidth <= tabletBreakpoint);
    }, [windowWidth]);

    if (loading)
        return (
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <ActivityIndicator size={"large"} />
            </View>
        );
    if (error)
        return (
            <View style={styles.centerContainer}>
                <Text>Fehler: {error}</Text>
            </View>
        );

    return (
        <View style={[isDesktop ? styles.desktopContainerWrapper : null, { padding: !isDesktop ? 20 : null }]}>
            <View style={orderHistoryStyles.backButtonContainer}>
                <CustomIconButton
                    icon="arrow-left"
                    tooltip="Zurück"
                    position="right"
                    onPress={() => props.history.push("/")}
                />
                <Text style={orderHistoryStyles.backButtonText}>Zurück zur Startseite</Text>
            </View>
            <View
                style={[
                    orderHistoryStyles.container,
                    isDesktop ? orderHistoryStyles.desktopContainer : orderHistoryStyles.mobileContainer,
                ]}
            >
                <View
                    nativeID="customer-navigation"
                    style={[
                        styles.customerNavigation,
                        isDesktop ? styles.customerNavigationDesktop : styles.customerNavigationMobile,
                    ]}
                >
                    <CustomerNavigation windowWidth={windowWidth} />
                </View>
                <View style={orderHistoryStyles.contentContainer}>
                    {isMobile ? (
                        <View>
                            {orders.length === 0 ? (
                                <Text>Keine Bestellungen gefunden</Text>
                            ) : (
                                orders.map((order) => (
                                    <List.Accordion
                                        key={order.ordersId.toString()}
                                        title={`Bestellung ${order.orderNumber}`}
                                        theme={accordionTheme}
                                        titleStyle={{ color: getContrastColor(theme.colors.backdrop) }}
                                        style={{ borderBottomWidth: 1, borderBottomColor: theme.colors.surface }}
                                    >
                                        <List.Item
                                            title={`Datum: ${new Date(order.orderDatetime).toLocaleDateString()}`}
                                        />
                                        <List.Item title={`Status: ${getOrderStatus(order.orderState, order)}`} />
                                        <List.Item
                                            title="Zur Bestellung"
                                            onPress={() =>
                                                history.push(`/orderTracking/${order.uid}`, { fromOrderHistory: true })
                                            }
                                            right={(props) => <List.Icon {...props} icon="arrow-right" />}
                                        />
                                    </List.Accordion>
                                ))
                            )}
                        </View>
                    ) : (
                        <View style={orderHistoryStyles.contentTableContainer}>
                            <DataTable style={orderHistoryStyles.tableDisabledCursor}>
                                <DataTable.Header>
                                    <DataTable.Title>Bestellnummer</DataTable.Title>
                                    <DataTable.Title>Datum</DataTable.Title>
                                    <DataTable.Title>Status</DataTable.Title>
                                    <DataTable.Title>Trackingseite</DataTable.Title>
                                </DataTable.Header>

                                {orders.length === 0 ? (
                                    <DataTable.Row>
                                        <DataTable.Cell>Keine Bestellungen gefunden</DataTable.Cell>
                                    </DataTable.Row>
                                ) : (
                                    orders.map((order) => (
                                        <DataTable.Row
                                            style={orderHistoryStyles.tableDisabledCursor}
                                            key={order.ordersId}
                                        >
                                            <DataTable.Cell style={orderHistoryStyles.tableDisabledCursor}>
                                                {order.orderNumber}
                                            </DataTable.Cell>
                                            <DataTable.Cell style={orderHistoryStyles.tableDisabledCursor}>
                                                {new Date(order.orderDatetime).toLocaleDateString()}
                                            </DataTable.Cell>
                                            <DataTable.Cell style={orderHistoryStyles.tableDisabledCursor}>
                                                {getOrderStatus(order.orderState, order)}
                                            </DataTable.Cell>
                                            <DataTable.Cell>
                                                <CustomIconButton
                                                    icon="arrow-right"
                                                    tooltip="Zur Bestellung"
                                                    position="right"
                                                    onPress={() =>
                                                        history.push(`/orderTracking/${order.uid}`, {
                                                            fromOrderHistory: true,
                                                        })
                                                    }
                                                />
                                            </DataTable.Cell>
                                        </DataTable.Row>
                                    ))
                                )}
                            </DataTable>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

const getOrderStatus = (orderState, order) => {
    switch (orderState) {
        case 0:
            return "Muss bestätigt werden";
        case 2:
            return "In Zubereitung";
        case 3:
            return "Zubereitet";
        case 4:
            return "In Auslieferung";
        case 5:
            if (order != null && order.stornoOrdersId != null) return "Storniert";
            return "Abgeschlossen";
        case 6:
            return "Fehlerhaft";
        case 7:
            return "Storniert";
        case 8:
            return "Genullt";
        case 9:
            return "Stornobuchung";
        default:
            return "Offen";
    }
};

export default OrderHistory;
